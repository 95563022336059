import React from "react";
import { useState } from "react";
import Loader from "./Loader.jsx";

const Createrequest = ({ name, role, f_newrequest }) => {
  return (
    <div className="col-12  col-lg-4 ">
      <div className="card   bg-white p-2 shadow  h-100 ">
        <h4 className=" text-secondary text-center ">Create Requests</h4>

        <div
          className=" bg-createrequests m-2 pb-3 shadow border btn fw-bold "
          onClick={() => {
            if (role == "requester") {
              f_newrequest(1);
            } else {
              alert("Please inform the requester to create the Request! 🙂");
            }
          }}
        >
          Sales return - Technical
        </div>
        <div
          className="bg-createrequests m-2 pb-3 shadow border btn  fw-bold "
          onClick={() => {
            if (role == "requester") {
              f_newrequest(2);
            } else {
              alert("Please inform the requester to create the Request! 🙂");
            }
          }}
        >
          Sales return - Sales
        </div>
      </div>
    </div>
  );
};

export default Createrequest;
