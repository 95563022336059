import React from "react";

const Subheader = ({ name, color, onClick }) => {
  return (
    <div>
      <div className="text-end text-gray me-3 text-small">
        <small>Application Version1 | Developed By PrabinKumar-ACS</small>
      </div>
      <div className="col-md-12 m-1">
        <div className=" text-center  p-1">
          <h2 className="text-header">ACS Workflow Management Solution</h2>
          <p className="text-secondary">
            Streamlines tasks, Increases transparency, Improves communication,
            Holds us accountable
          </p>
        </div>
      </div>
    </div>
  );
};
export default Subheader;
