// import Button from "./components/Button.jsx";
import Body from "./components/Body.jsx";

import Login from "./components/Login.jsx";
function App() {
  return (
    <div>
      <Body />
    </div>
  );
}

export default App;
