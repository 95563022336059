import React from "react";

const Pendingtasks = ({ role, user, allrequests, f_openrequest }) => {
  var element = allrequests.map((item, index) => {
    if (
      item.status.toLowerCase() !== "completed" &&
      item.heldwith?.toLowerCase() === user.toLowerCase()
    ) {
      return (
        <div
          key={index}
          className="btn  ms-4 me-4 mb-2 p-2  bg-lightgray   border fw-bold  "
          onClick={() => f_openrequest(item.id)}
        >
          ✔️ {item.type} | Request Date: {item.createdate} | Received On:{" "}
        </div>
      );
    } else return null;
  });

  var k = element.filter((item) => item !== null);
  //console.log(element);
  if (k.length === 0) {
    element = (
      <div className="card p-2 bg-completed text-center  m-2 shadow border  ">
        No Pending Tasks!🙂
      </div>
    );
  }
  return element;
};
export default Pendingtasks;
