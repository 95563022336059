import React from "react";
const Allrequests = ({ name, allrequests, f_openrequest }) => {
  const html = allrequests.map((item, index) => {
    var status = "Completed ✅";
    var bg = "bg-completed";
    if (item.status.toLowerCase() !== "completed") {
      bg = "bg-ongoing ";
      status = "Ongoing ⏳";
    }
    return (
      <div
        key={index}
        className={`btn text-start  ms-4 me-4 mb-2 p-2 ${bg} border fw-bold`}
        onClick={() => f_openrequest(item.id)}
      >
        Request ID: {item.id} | Request Date: {item.createdate} | Customer:{" "}
        {item.customer}
        <div>
          {" "}
          {item.type} | Amount: {item.amount} | OSF No: {item.osf} | Status:{" "}
          {status}
        </div>
      </div>
    );
  });

  return html;
};
export default Allrequests;
