import React from "react";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Header = ({ user, color, onClick }) => {
  return (
    <>
      <div className=" bg-light p-1 mb-2 row">
        <div className="col">
          {" "}
          <img
            src="../images/logo.png"
            alt=""
            className="ms-5"
            height={"60px"}
          />
        </div>
        <div className="col  text-end  m-3">
          <label htmlFor="logout">{user}</label>
          <button
            id="logout"
            onClick={() => {
              cookies.set("user", "", { path: "/" });
              window.location.reload();
            }}
            className="btn btn-sm btn-danger m-1 "
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
