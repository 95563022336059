import axios from "axios";
//import fileDownload from "js-file-download";
var apiserver = "https://workflow.acshelpdesk.com/";
//var apiserver = "http://localhost:5000/"; //production server
//https://www.workflow.acshelpdesk.com/getallapprovalorder
//https://www.workflow.acshelpdesk.com/getallrequest
//https://www.workflow.acshelpdesk.com/getallrequesthistory

//###############-------function2--------###############

export async function GetApi(url, params) {
  var baseurl = apiserver + url;
  var result = await axios
    .get(baseurl, params)
    .then(function (res) {
      // console.log(res.data);
      return res.data;
    })
    .catch(function (error) {
      //console.log(error.response.status);
      // if (error.response) {
      //   if (error.response.status == 412) {
      return [];
      // }
      // } else return "connection error";
    })
    .finally(function () {});

  return result;
}

//###############-------function3--------###############
export async function PostApi(url, params) {
  var baseurl = apiserver + url;
  var result = axios
    .post(baseurl, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return "networkerror";
    });

  return result;
}

//###############-------function1--------###############
// export function DateToArabic(data) {
//   try {
//     var date = new Date(data);
//     var months = [
//       "يناير",
//       "فبراير",
//       "مارس",
//       "إبريل",
//       "مايو",
//       "يونيو",
//       "يوليو",
//       "أغسطس",
//       "سبتمبر",
//       "أكتوبر",
//       "نوفمبر",
//       "ديسمبر",
//     ];
//     var days = [
//       "اﻷحد",
//       "اﻷثنين",
//       "الثلاثاء",
//       "اﻷربعاء",
//       "الخميس",
//       "الجمعة",
//       "السبت",
//     ];
//     var delDateString =
//       days[date.getDay()] +
//       ", " +
//       date.getDate() +
//       " " +
//       months[date.getMonth()] +
//       ", " +
//       date.getFullYear();
//     // console.log(delDateString);

//     return delDateString; // Outputs اﻷحد, 4 ديسمبر, 2016
//   } catch (error) {
//     if (data) return data;
//     else return "failed to convert date";
//   }
// }
//#####################--------DownloadAPI-------------------
// export async function DownloadApi(url, props) {
//   var baseurl = apiserver + url;
//   var result = await axios
//     .get(baseurl, {
//       params: { id: props.id },
//       responseType: "blob",
//     })
//     .then(function (res) {
//       fileDownload(res.data, `ServiceReport_${props.id}.pdf`);
//       return "success";
//     })
//     .catch(function (error) {
//       //console.log(error.response.status);
//       // if (error.response) {
//       //   if (error.response.status == 412) {
//       return "networkerror";
//       // }
//       // } else return "connection error";
//     })
//     .finally(function () {});

//   return result;
// }
