import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GetApi } from "./Commonfunctions.jsx";
import Createrequests from "./Createrequest.jsx";
import Allrequests from "./Allrequests.jsx";
import Pendingtasks from "./Pendingtasks.jsx";
import Loader from "./Loader.jsx";
import Salesreturnform from "./Salesreturnform.jsx";
import Request from "./Request.jsx";
import Getcookie from "./Getcookie.jsx";
import Header from "./Header.jsx";
import Subheader from "./Subheader.jsx";
import Cookies from "universal-cookie";

let user = Getcookie("user");
let role = Getcookie("role");
var allrequests = [];
var allrequesthistory = [];
var allapprovalorder = [];
user = "";

role = "approver";
const Body = () => {
  //const [allrequests, setAllrequests] = useState([]);
  const [page, setPage] = useState(Loader);
  useEffect(() => {
    async function getallrequest() {
      allrequests = await GetApi("getallrequest", {});
    }
    async function getallrequesthistory() {
      allrequesthistory = await GetApi("getallrequesthistory", {});
    }
    async function getallapprovalorder() {
      allapprovalorder = await GetApi("getallapprovalorder", {});
    }

    async function start() {
      await getallrequest();
      await getallrequesthistory();
      await getallapprovalorder();
      console.log();
      if (
        !(
          allrequesthistory.length > 0 &&
          allrequests.length > 0 &&
          allapprovalorder.length > 0 &&
          !allrequesthistory.includes("error") &&
          !allrequests.includes("error") &&
          !allapprovalorder.includes("error")
        )
      ) {
        setPage("Server Communication Error!");
      } else {
        f_loadpage();
      }

      //f_openrequest(2);
    }

    async function getuser() {
      const cookies = new Cookies();

      console.log(cookies.get("user"));

      let u = cookies.get("user");
      console.log(u);
      if (!u) {
        let username = prompt(
          "Please enter your name! \r\nAvailable users: sophia, sreekumar, gregory, aslam, abhijit ",
          "Example:sophia"
        );
        if (
          username == "sophia" ||
          username == "sreekumar" ||
          username == "gregory" ||
          username == "aslam" ||
          username == "abhijit"
        ) {
          cookies.set("user", username, { path: "/" });
          user = username;
          if (username == "sophia") {
            role = "requester";
          }

          start();
        }
      } else {
        console.log("here");
        user = u;
        if (u == "sophia") {
          role = "requester";
        }

        start();
      }
    }
    getuser();
  }, []);

  function f_openrequest(id) {
    setPage(
      <Request
        allrequests={allrequests}
        allrequesthistory={allrequesthistory}
        allapprovalorder={allapprovalorder}
        id={id}
        f_loadpage={f_loadpage}
        user={user}
        role={role}
      />
    );
  }
  function f_newrequest(id) {
    setPage(<Salesreturnform id={id} user={user} f_loadpage={f_loadpage} />);
  }
  function f_downloadrequest(id) {
    alert(id);
  }

  function f_loadpage() {
    //console.log(allrequests);
    const element = (
      <div>
        <div className="row m-2 ">
          <div className="col-12  col-lg-8">
            <div className="card   bg-white p-2 shadow  h-100 ">
              <h4 className=" text-secondary text-center ">Pending Tasks</h4>
              {allrequests.length > 0 ? (
                <Pendingtasks
                  allrequests={allrequests}
                  f_openrequest={f_openrequest}
                  user={user}
                  role={role}
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <Createrequests role={role} f_newrequest={f_newrequest} />
        </div>

        <div className="card shadow fw-bold  bg-white p-2 m-4 ">
          <h4 className="card-title text-secondary ms-4  p-1">All Requests</h4>
          {allrequests.length > 0 ? (
            <Allrequests
              allrequests={allrequests}
              f_openrequest={f_openrequest}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
    setPage(element);
  }

  return (
    <div>
      <Header user={user} />
      <section className=" card m-3 shadow bg-lightgray">
        <Subheader />
        {page}
      </section>
    </div>
  );
};

export default Body;
