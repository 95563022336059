import { DNA } from "react-loader-spinner";
function Loader() {
  return (
    <DNA
      visible={true}
      height="60"
      width="60"
      ariaLabel="dna-loading"
      wrapperStyle={{}}
      wrapperClass="dna-wrapper"
    />
  );
}

export default Loader;

// {/* <Watch
//   height="40"
//   width="40"
//   radius="48"
//   color="#FF8000"
//   ariaLabel="watch-loading"
//   wrapperStyle={{}}
//   wrapperClassName=""
//   visible={true}
// />; */}
