import React from "react";

const Salesreturnform = ({ id, user, f_loadpage }) => {
  return (
    <div>
      <div className="text-end me-5 p-2">
        <button
          className="btn btn-sm btn-warning "
          onClick={() => {
            f_loadpage();
          }}
        >
          Cancel/Back
        </button>
      </div>
      <form
        method="get"
        action="https://workflow.acshelpdesk.com/createrequest"
        className="card bg-light p-2 ms-5 me-5"
      >
        <div className="p-2 ">
          <h3 className="text-center ">Sales Return Form</h3>
          <div className="mb-2 ">
            Customer Details
            <input
              type="text"
              className="form-control shadow"
              name="customer"
            />
          </div>
          <div className="mb-2 ">
            Amount
            <input type="text" className="form-control shadow" name="amount" />
          </div>
          <div className="mb-2 ">
            OSF Number
            <input type="text" className="form-control shadow" name="osf" />
          </div>
          <div className="mb-2 ">
            Reason
            <input type="text" className="form-control shadow" name="reason" />
          </div>
          <div className="mb-2 ">
            Remarks
            <textarea
              type="text"
              className="form-control shadow"
              name="remarks"
            />
            <div hidden>
              <input name="id" value={id} />
              <input name="user" value={user} />
              <input name="type" value="Sales return" />
            </div>
          </div>
          <div className="text-end m-2 p-2">
            <button type="submit" className="btn btn-sm btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Salesreturnform;
