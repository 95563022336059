import React from "react";

const Request = ({
  allapprovalorder,
  allrequests,
  allrequesthistory,
  id,
  f_loadpage,
  user,
  role,
}) => {
  let result = allrequests.find((item) => item.id == id);
  var thisorder = allapprovalorder.filter(function (item) {
    return item.orderid == result.orderid;
  });
  // console.log(result);
  // console.log(thisorder);
  let userindex = thisorder.findIndex(
    (item) => item.user.toLowerCase() == user.toLowerCase()
  );
  // console.log(userindex);
  let nextuser = "";

  if (user !== "abhijit") {
    nextuser = thisorder[userindex + 1].user;
  }

  //console.log(nextuser);
  let completed = false;
  if (result.status.toLowerCase() == "completed") {
    completed = true;
  }
  //console.log(result);
  //console.log(allrequesthistory);
  var filteredhistory = allrequesthistory.filter(function (el) {
    return el.requestid == id;
  });
  let sreekumar = filteredhistory.find(
    (item) => item.updatedby.toLowerCase() == "sreekumar"
  );

  let gregory = filteredhistory.find(
    (item) => item.updatedby.toLowerCase() == "gregory"
  );
  let aslam = filteredhistory.find(
    (item) => item.updatedby.toLowerCase() == "aslam"
  );
  let abhijit = filteredhistory.find(
    (item) => item.updatedby.toLowerCase() == "abhijit"
  );

  //console.log(sreekumar, gregory, aslam, abhijit);
  const flow = filteredhistory.map((item, index) => {
    return (
      <div key={index}>
        {" "}
        <div className="card p-2 shadow ">
          ✅ {item.updatedate} | {item.updates} <br /> Remarks/Comments:{" "}
          {item.comments}
        </div>
        <div className="text-center">
          <img src="/images/arrow.png" alt="" />
        </div>
      </div>
    );
  });

  const element = (
    <div>
      <div className="text-end">
        {" "}
        <button
          className="btn btn-sm btn-warning me-3"
          onClick={() => {
            f_loadpage();
          }}
        >
          Cancel / Return
        </button>
      </div>
      <div className="row p-2 mb-5">
        <div className="col-sm-8 ">
          <div className="card  bg-light h-100">
            <div className="card-body">
              <h5 className="card-title text-center">
                {result.type} | Request Date : {result?.createdate} | Request ID
                : {result?.id}
              </h5>
              {!completed ? (
                <div className=" p-2 m-2 fw-bold bg-warning  ">
                  Current Status: Waiting for response from{" "}
                  {result?.heldwith.replace(/\b[a-z]/g, (x) => x.toUpperCase())}
                </div>
              ) : (
                <div className=" p-2 m-2 fw-bold bg-success text-white ">
                  {" "}
                  Current Status: Closed
                </div>
              )}
              <div className="card p-2 m-2 shadow ">
                Customer: {result?.customer}
              </div>
              <div className="card p-2 m-2 shadow ">
                OSF Number: {result?.osf}
              </div>
              <div className="card p-2 m-2 shadow">
                Amount: {result?.amount}
              </div>
              <div className="card p-2 m-2 shadow">
                Status: {result?.status}
              </div>
              <div className="card p-2 m-2 shadow">
                Reason: {result?.reason}
              </div>
              <div className="card p-2 m-2 shadow"> GRN No: {result?.grn}</div>
              <div className="card p-2 m-2 shadow">
                Credit Note No: {result?.creditnote}
              </div>

              {user == result.heldwith ? (
                <form
                  method="get"
                  action="https://workflow.acshelpdesk.com/updaterequest"
                >
                  <div hidden>
                    <input name="id" value={id} />
                    <input name="user" value={user} />
                    <input name="type" value="Sales return" />
                    <input name="nextuser" value={nextuser} />
                  </div>
                  <div className="card p-2 m-2 bg-lightgray">
                    <label className="col-form-label">Comments: </label>
                    <input
                      type="text"
                      className="form-control"
                      name="comments"
                    />

                    <div className="text-end p-2">
                      <button className="btn btn-sm btn-success">
                        Approve
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
              <div className="p-2 m-2 fw-bold">Approvals:-</div>
              <div className="p-2 m-2">
                Sreekumar: {sreekumar ? <>Approved ✅</> : <>Waiting ⏳</>}
              </div>
              <div className="p-2 m-2">
                Gregory: {gregory ? <>Approved ✅</> : <>Waiting ⏳</>}{" "}
              </div>
              <div className="p-2 m-2">
                {" "}
                Aslam: {aslam ? <>Approved ✅</> : <>Waiting ⏳</>}
              </div>
              <div className="p-2 m-2">
                {" "}
                Abhijit: {abhijit ? <>Approved ✅</> : <>Waiting ⏳</>}
              </div>
              {!completed ? (
                <div className=" p-2 m-2 fw-bold bg-warning  "></div>
              ) : (
                <div className=" p-2 m-2 fw-bold bg-success text-white "> </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-4 ">
          <div className="card  bg-createrequests h-100">
            <div className="card-body">
              <h5 className="card-title text-center">Activity Flow</h5>
              {flow}
              {completed ? (
                <div className="card p-2 shadow text-center ">Completed</div>
              ) : (
                <div className="card p-2 shadow ">❓ Waiting For Approval!</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return element;
};

export default Request;
